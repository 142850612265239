<template>
  <v-snackbar v-model="show" :timeout="timeout" :color="type" top right>
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn v-if="closeable" icon v-bind="attrs" @click="show = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snackbar",
  props: {
    message: {
      default: '',
    },
    timeout: {
      default: 3000,
    },
    closeable: {
      default: false,
    }
  },
  data() {
    return {
      show:false,
      type:'success',
    }
  }
}
</script>

