const equipments = [
    {
        "name": 'Free Wifi',
        "icon": 'mdi mdi-wifi',
        "selected": false,
    },
    {
        "name": 'Projector',
        "icon": 'mdi mdi-projector',
        "selected": false,
    },
    {
        "name": 'Presenter',
        "icon": 'mdi mdi-room-service',
        "selected": false,
    },
    {
        "name": 'Stage',
        "icon": 'mdi mdi-shore',
        "selected": false,
    },
    {
        "name": 'Canvas',
        "icon": 'mdi mdi-crop-square',
        "selected": false,
    },
    {
        "name": 'Onsite Restaurants',
        "icon": 'mdi mdi-food',
        "selected": false,
    },
    {
        "name": 'Flatscreens',
        "icon": 'mdi mdi-monitor',
        "selected": false,
    },
    {
        "name": 'Meeting Rooms',
        "icon": 'mdi mdi-google-classroom',
        "selected": false,
    },
    {
        "name": 'Onsite Bar',
        "icon": 'mdi mdi-glass-cocktail',
        "selected": false,
    },
    {
        "name": 'Flip Cart',
        "icon": 'mdi mdi-presentation',
        "selected": false,
    },
    {
        "name": 'Onsite Gift Shop',
        "icon": 'mdi mdi-gift',
        "selected": false,
    },
    {
        "name": 'Onsite Catering',
        "icon": 'mdi mdi-room-service',
        "selected": false,
    },
    {
        "name": 'Writing Accessories',
        "icon": 'mdi mdi-book-open-variant',
        "selected": false,
    },
    {
        "name": 'AC in all rooms',
        "icon": 'mdi mdi-air-conditioner',
        "selected": false,
    },
    {
        "name": 'Sound System',
        "icon": 'mdi mdi-file-music',
        "selected": false,
    },
    {
        "name": 'Printer',
        "icon": 'mdi mdi-printer',
        "selected": false,
    },
    {
        "name": 'Dimmable Lighting',
        "icon": 'mdi mdi-ceiling-light',
        "selected": false,
    },
    {
        "name": 'Minibar',
        "icon": 'mdi mdi-glass-cocktail',
        "selected": false,
    },
    {
        "name": 'Balcony',
        "icon": 'mdi mdi-home-minus',
        "selected": false,
    },
    {
        "name": 'Wake-Up Service',
        "icon": 'mdi mdi-alarm',
        "selected": false,
    },
];

const hotelFeatures = [
    {
        "name": 'Free Wifi',
        "icon": 'mdi mdi-wifi',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'Gift Shop',
        "icon": 'mdi mdi-gift',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'Spa',
        "icon": 'mdi mdi-spa',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'Pet Friendly',
        "icon": 'mdi mdi-cat',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'Pool',
        "icon": 'mdi mdi-pool',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'Airport Shuttles',
        "icon": 'mdi mdi-airport',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'Rent-a-car',
        "icon": 'mdi mdi-car',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'Rent-a-bike',
        "icon": 'mdi mdi-bike',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'Sport Area',
        "icon": 'mdi mdi-table-tennis',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'Gym',
        "icon": 'mdi mdi-dumbbell',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'Beauty Salon',
        "icon": 'mdi mdi-hair-dryer',
        "selected": false,
        "removable": false,
    },
];

const cateringTypes = [
    {
        "name": 'In-house Catering',
        "icon": 'mdi mdi-room-service',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'External Catering',
        "icon": 'mdi mdi-room-service',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'Fixed Catering Partner',
        "icon": 'mdi mdi-account-supervisor',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'Extra Catering Area',
        "icon": 'mdi mdi-tape-measure',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'Extra Catering Entrance',
        "icon": 'mdi mdi-door-open',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'Signature Kitchen',
        "icon": 'mdi mdi-food-variant',
        "selected": false,
        "removable": false,
    },
    {
        "name": 'Conference Packages',
        "icon": 'mdi mdi-presentation',
        "selected": false,
        "removable": false,
    },
];

const locationOpeningHours = [
    {
        'day': 'Monday',
        'start_time': '00:00',
        'end_time': '00:00',
        'is_open': true,
    },
    {
        'day': 'Tuesday',
        'start_time': '00:00',
        'end_time': '00:00',
        'is_open': true,
    },
    {
        'day': 'Wednesday',
        'start_time': '00:00',
        'end_time': '00:00',
        'is_open': true,
    },
    {
        'day': 'Thursday',
        'start_time': '00:00',
        'end_time': '00:00',
        'is_open': true,
    },
    {
        'day': 'Friday',
        'start_time': '00:00',
        'end_time': '00:00',
        'is_open': true,
    },
    {
        'day': 'Saturday',
        'start_time': '00:00',
        'end_time': '00:00',
        'is_open': false,
    },
    {
        'day': 'Sunday',
        'start_time': '00:00',
        'end_time': '00:00',
        'is_open': false,
    },
];

const locationSeasonalTimings = [
    {
        'start_date': new Date().toISOString().substr(0, 10),
        'end_date': new Date().toISOString().substr(0, 10),
        'start_date_menu': false,
        'end_date_menu': false,
        'removable': false,
    },
];

const hotelOpeningHours = [
    {
        'day': 'Monday',
        'start_time': '00:00',
        'end_time': '00:00',
        'is_open': true,
    },
    {
        'day': 'Tuesday',
        'start_time': '00:00',
        'end_time': '00:00',
        'is_open': true,
    },
    {
        'day': 'Wednesday',
        'start_time': '00:00',
        'end_time': '00:00',
        'is_open': true,
    },
    {
        'day': 'Thursday',
        'start_time': '00:00',
        'end_time': '00:00',
        'is_open': true,
    },
    {
        'day': 'Friday',
        'start_time': '00:00',
        'end_time': '00:00',
        'is_open': true,
    },
    {
        'day': 'Saturday',
        'start_time': '00:00',
        'end_time': '00:00',
        'is_open': false,
    },
    {
        'day': 'Sunday',
        'start_time': '00:00',
        'end_time': '00:00',
        'is_open': false,
    },
];

const hotelSeasonalTimings = [
    {
        'start_date': new Date().toISOString().substr(0, 10),
        'end_date': new Date().toISOString().substr(0, 10),
        'start_date_menu': false,
        'end_date_menu': false,
        'removable': false,
    },
];

const hotelTypes = [
    {
        "name": 'Business',
        "icon": 'mdi mdi-office-building-outline',
        "selected": false,
    },
    {
        "name": 'Beach',
        "icon": 'mdi mdi-beach',
        "selected": false,
    },
    {
        "name": 'City',
        "icon": 'mdi mdi-city',
        "selected": false,
    },
    {
        "name": 'Boutique',
        "icon": 'mdi mdi-shopping',
        "selected": false,
    },
    {
        "name": 'Family',
        "icon": 'mdi mdi-home-account',
        "selected": false,
    },
    {
        "name": 'Luxury',
        "icon": 'mdi mdi-office-building-outline',
        "selected": false,
    },
    {
        "name": 'Countrys.',
        "icon": 'mdi mdi-beach',
        "selected": false,
    },
    {
        "name": 'Mountains',
        "icon": 'mdi mdi-slope-uphill',
        "selected": false,
    },
    {
        "name": 'Sport',
        "icon": 'mdi mdi-car-sports',
        "selected": false,
    },
    {
        "name": 'Wellness',
        "icon": 'mdi mdi-hand-heart',
        "selected": false,
    },
];

export {
    equipments, cateringTypes, locationOpeningHours, locationSeasonalTimings, hotelOpeningHours, hotelSeasonalTimings,
    hotelTypes, hotelFeatures,
};