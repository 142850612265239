<template>
  <div>
    <snackbar ref="snackbar" :message="validationMessage"></snackbar>
    <v-navigation-drawer
      app
      clipped
      width="480px"
      v-model="drawer"
      class="bglight"
      :style="[mini ? { left: '56px' } : { left: '232px' }]"
    >
      <v-card color="bglight" tile flat class="pa-4">
        <div>
          <v-overlay class="ml-overlay" color="white" :value="getOverlay" opacity="0.3" z-index="1500"></v-overlay>
          <v-row>
            <v-col cols="9">
              <v-text-field
                v-model="searchVendors"
                class="mx-4 style-search"
                hide-no-data
                hide-details
                label="Search Venue or Hotel"
                append-icon="mdi-magnify"
                flat
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-btn
                color="accent"
                depressed
                class="add-vendor-btn"
                @click="addNewVendor"
                v-if="getAuthUser.user_type === 'admin' || getAuthUser.user_role === 'master'"
                >Add New</v-btn
              >
            </v-col>
          </v-row>
          <v-chip-group active-class="primary" class="mt-3" column mandatory v-model="filterStatus">
            <v-chip value="all" class="text-uppercase" label small :outlined="filterStatus !== 'all'">
              All
            </v-chip>
            <v-chip value="active" class="text-uppercase" label small :outlined="filterStatus !== 'active'">
              Active
            </v-chip>
            <v-chip value="inactive" class="text-uppercase" label small :outlined="filterStatus !== 'inactive'">
              Inactive
            </v-chip>
            <v-chip value="draft" class="text-uppercase" label small :outlined="filterStatus !== 'draft'">
              Drafts
            </v-chip>
            <v-chip value="hide" class="text-uppercase" label small :outlined="filterStatus !== 'hide'">
              Hidden
            </v-chip>
          </v-chip-group>
          <v-btn-toggle text color="primary" class="px-0 py-0 mx-0 my-0" group mandatory v-model="filterType">
            <v-btn value="all" small>All</v-btn>
            <v-btn value="hotel" small>Hotels</v-btn>
            <v-btn value="location" small>Venues</v-btn>
          </v-btn-toggle>
          <organization-selector @org-selected="orgSelected" label="Select Organization"></organization-selector>
        </div>

        <div v-if="getVendorsLoadStatus === 2">
          <div v-for="(vendor, index) in getVendors" :key="index">
            <!--Hotel Listing (START)-->
            <!--             @click="getVendorByType({id: vendor.id}, vendor.type)"-->
            <v-card
              v-if="vendor.type === 'hotel'"
              class="my-3"
              hover
              @click="getVendorByType($event, { id: vendor.id }, vendor.type)"
            >
              <div class="px-4 py-2">
                <div class="text-overline">Hotel</div>
                <v-row>
                  <v-col cols="9">
                    <div class="text-subtitle-2 text-truncate">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{ vendor.name }}</span>
                        </template>
                        <span>{{ vendor.name }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-col cols="3 pt-0">
                    <vendor-listing-actions
                      :vendor="vendor"
                      @subscription-message="showSubscriptionSnackBar"
                    ></vendor-listing-actions>
                  </v-col>
                </v-row>
                <div class="text-body-2 text-truncate">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{ vendor.address }}</span>
                    </template>
                    <span>{{ vendor.address }}</span>
                  </v-tooltip>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="justify-start pa-4 d-flex">
                <div v-if="vendor.logoImage">
                  <v-img class="mr-4 preview-image" max-width="90px" :src="vendor.logoImage.url"></v-img>
                </div>
                <div v-else>
                  <v-img class="mr-4 preview-image" max-width="90px" src="@/assets/images/grey.jpg"></v-img>
                </div>
                <div class="d-flex flex-column">
                  <div class="d-flex justify-space-between">
                    <div class="mr-2 d-flex flex-column align-start">
                      <div class="my-1 text-overline text--secondary line-normal">
                        Total Rooms
                      </div>
                      <div class="justify-start d-flex align-center">
                        <v-icon>mli-bed-side</v-icon>
                        <span class="ml-2 text-truncate item-width" v-if="vendor.total_rooms">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span dark v-bind="attrs" v-on="on">
                                {{ vendor.total_rooms | commaSeparator }}
                              </span>
                            </template>
                            <span>{{ vendor.total_rooms | commaSeparator }}</span>
                          </v-tooltip>
                        </span>
                        <p v-else class="ml-1 font-italic margin">N/A</p>
                      </div>
                    </div>

                    <div class="ml-2 d-flex flex-column align-start">
                      <div class="my-1 text-overline text--secondary line-normal">
                        Largest Room
                      </div>
                      <div class="justify-start d-flex align-center">
                        <v-icon>mli-plan</v-icon>
                        <span class="ml-2 text-truncate item-width" v-if="vendor.largest_room > 0">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span dark v-bind="attrs" v-on="on">
                                {{ vendor.largest_room | commaSeparator }} sqm
                              </span>
                            </template>
                            <span>{{ vendor.largest_room | commaSeparator }} sqm</span>
                          </v-tooltip>
                        </span>
                        <p v-else class="ml-1 font-italic margin">N/A</p>
                      </div>
                    </div>

                    <div class="ml-2 d-flex flex-column align-start">
                      <div class="my-1 text-overline text--secondary line-normal">
                        Event Spaces
                      </div>
                      <div class="justify-start d-flex align-center">
                        <v-icon>mli-event-space-category</v-icon>
                        <span class="ml-2 text-truncate item-width" v-if="vendor.total_event_spaces > 0">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span dark v-bind="attrs" v-on="on">
                                {{ vendor.total_event_spaces | commaSeparator }}
                              </span>
                            </template>
                            <span>{{ vendor.total_event_spaces | commaSeparator }}</span>
                          </v-tooltip>
                        </span>
                        <p v-else class="mx-1 font-italic margin">N/A</p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3 text-truncate" v-if="vendor.hotel_features.length > 0">
                    <div class="width">
                      <v-chip
                        :key="'hf' + index"
                        v-for="(vendor, index) in vendor.hotel_features"
                        label
                        small
                        class="mb-2 mr-2 text-uppercase text-truncate"
                        v-if="index < 2"
                        >{{ vendor.name }}</v-chip
                      >
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
            <!--Hotel Listing (END)-->

            <!--Location Listing (START)-->
            <v-card
              v-if="vendor.type === 'location'"
              class="my-3"
              hover
              @click="getVendorByType($event, { id: vendor.id }, vendor.type)"
            >
              <div class="px-4 py-2">
                <div class="text-overline">Venue</div>
                <v-row>
                  <v-col cols="9">
                    <div class="text-subtitle-2 text-truncate">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{ vendor.name }}</span>
                        </template>
                        <span>{{ vendor.name }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-col cols="3 pt-0">
                    <vendor-listing-actions
                      :vendor="vendor"
                      @subscription-message="showSubscriptionSnackBar"
                    ></vendor-listing-actions>
                  </v-col>
                </v-row>
                <div class="text-body-2 text-truncate">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{ vendor.address }}</span>
                    </template>
                    <span>{{ vendor.address }}</span>
                  </v-tooltip>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="justify-start pa-4 d-flex">
                <div v-if="vendor.logoImage">
                  <v-img class="mr-4 preview-image" max-width="90px" :src="vendor.logoImage.url"></v-img>
                </div>
                <div v-else>
                  <v-img
                    class="mr-4 rounded-lg"
                    max-width="90px"
                    max-height="90px"
                    height="100px"
                    src="@/assets/images/grey.jpg"
                  ></v-img>
                </div>
                <div class="d-flex flex-column">
                  <div class="d-flex justify-space-between">
                    <div class="mr-2 d-flex flex-column align-start">
                      <div class="my-1 text-overline text--secondary line-normal">
                        Event Spaces
                      </div>
                      <div class="justify-start d-flex align-center">
                        <v-icon>mli-event-space-category</v-icon>
                        <span class="ml-2 text-truncate item-width" v-if="vendor.total_event_spaces > 0">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span dark v-bind="attrs" v-on="on">
                                {{ vendor.total_event_spaces | commaSeparator }}
                              </span>
                            </template>
                            <span>{{ vendor.total_event_spaces | commaSeparator }}</span>
                          </v-tooltip>
                        </span>
                        <span class="ml-2 font-italic" v-else>N/A</span>
                      </div>
                    </div>
                    <div class="mr-2 d-flex flex-column align-start">
                      <div class="my-1 text-overline text--secondary line-normal">
                        Largest Room
                      </div>
                      <div class="justify-start d-flex align-center">
                        <v-icon>mli-plan</v-icon>
                        <span class="ml-2 text-truncate item-width" v-if="vendor.largest_room > 0">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span dark v-bind="attrs" v-on="on">
                                {{ vendor.largest_room | commaSeparator }} sq. m
                              </span>
                            </template>
                            <span>{{ vendor.largest_room | commaSeparator }} sq. m</span>
                          </v-tooltip>
                        </span>
                        <span class="ml-2 font-italic" v-else>N/A</span>
                      </div>
                    </div>
                    <div class="mr-2 d-flex flex-column align-start">
                      <div class="my-1 text-overline text--secondary line-normal">
                        Basic Rent
                      </div>
                      <div class="justify-start d-flex align-center">
                        <v-icon>mli-payment-euro</v-icon>
                        <span class="ml-2 text-truncate item-width" v-if="vendor.basic_rent">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span dark v-bind="attrs" v-on="on">
                                {{ vendor.basic_rent | commaSeparator }}
                              </span>
                            </template>
                            <span>{{ vendor.basic_rent | commaSeparator }}</span>
                          </v-tooltip>
                        </span>
                        <span class="ml-2 font-italic" v-else>N/A</span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3 text-truncate" v-if="vendor.equipments_list && vendor.equipments_list.length > 0">
                    <div class="width">
                      <v-chip
                        :key="'esl' + index"
                        v-for="(item, index) in vendor.equipments_list"
                        label
                        small
                        class="mb-2 mr-2 text-uppercase text-truncate"
                        v-if="index < 2"
                        >{{ item }}</v-chip
                      >
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
            <!--Location Listing (END)-->
          </div>
          <infinite-loading :identifier="loaderId" @infinite="infiniteHandler"></infinite-loading>
        </div>

        <div v-else-if="getVendorsLoadStatus === 0">
          <v-skeleton-loader
            v-for="n in 5"
            class="mt-5"
            :key="n"
            type="list-item-avatar-three-line"
          ></v-skeleton-loader>
        </div>
      </v-card>
    </v-navigation-drawer>
    <v-scroll-x-reverse-transition mode="out-in">
      <new-vendor @showMessage="showMessage" :form="form" @loadComponent="loadComponent"></new-vendor>
    </v-scroll-x-reverse-transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Snackbar from "@/components/Snackbar";
import {
  cateringTypes,
  equipments,
  hotelFeatures,
  hotelSeasonalTimings,
  hotelTypes,
  locationOpeningHours
} from "../../store/models/Constants";
const NewVendor = () => import(/* webpackChunkName: "new-vendor" */ "./NewVendor");
const VendorListingActions = () => import(/* webpackChunkName: "components" */ "@/components/VendorListingActions");
export default {
  name: "VendorListing",
  props: ["mini"],
  components: { NewVendor, Snackbar, VendorListingActions },
  data: () => ({
    filterStatus: "all",
    filterType: "all",
    filterOrganization: null,
    loaderId: +new Date(),
    drawer: true,
    form: null,
    searchVendors: null,
    outside: [],
    meeting_rooms: [],
    logo: [],
    rooms: [],
    restaurants: [],
    bars: [],
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    organization_id: null,
    selectedOrganizationId: null,
    validationMessage: "",
    timeout: null
  }),
  computed: {
    ...mapGetters([
      // Vendor
      "getVendors",
      "getNewVendor",
      "getNewVendorSpecification",
      "getNewVendorSpace",

      "getToggleSlider",
      "getVendorsLoadStatus",
      "getActiveComponent",

      "getVendor",
      "getVendorSpecification",
      "getVendorSpace",
      "getIsVendorUpdate",

      "getAuthUser",
      "isAdmin",
      "getOrganizations",
      "getVendorUpdateStatus",
      "getSubscriptionUpdateStatus",
      "getOffset",
      "getOverlay"
    ])
  },
  filters: {
    commaSeparator: value => {
      if (value && value % 1 != 0) {
        value = value.toString().replace(",", ".");
        value = parseFloat(value).toFixed(1);
      }

      return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : value;
    }
  },
  methods: {
    showMessage(snackbar = {}) {
      this.validationMessage = snackbar.message;
      this.$refs.snackbar.type = snackbar.type;
      this.$refs.snackbar.show = snackbar.show;
    },
    async infiniteHandler($state) {
      let len = await this.loadVendors({
        status: this.filterStatus,
        type: this.filterType,
        organization_id: this.filterOrganization,
        name: this.searchVendors
      });
      if (len && !!$state) {
        $state.loaded();
      } else if (!!$state) $state.complete();
    },
    showSubscriptionSnackBar(type) {
      if (this.getSubscriptionUpdateStatus === 2) {
        if (type === "paused") {
          this.validationMessage = "Subscription has been paused successfully";
          this.$refs.snackbar.type = "success";
          this.$refs.snackbar.show = true;
        } else if (type === "cancelled") {
          this.validationMessage = "Subscription has been cancelled successfully";
          this.$refs.snackbar.type = "success";
          this.$refs.snackbar.show = true;
        }
      } else {
        this.validationMessage = "Something went wrong";
        this.$refs.snackbar.type = "error";
        this.$refs.snackbar.show = true;
      }
    },
    getVendorByType(event, vendorObj, type = "hotel") {
      if (event) {
        if (event.target.tagName != "I" && event.target.tagName != "BUTTON") {
          this.setActiveVendorType(type);
          Object.assign(vendorObj, { mlforceload: true });
          this.loadVendor(vendorObj);
        }
      }
    },
    loadComponent(type = "hotel") {
      if (type !== "home") {
        this.initForm(type);
      }
    },
    async initForm(type) {
      let vendorStore = this.getNewVendor;
      let vendorSpecification = this.getNewVendorSpecification;
      let vendorSpaces = this.getNewVendorSpace;

      if (this.getIsVendorUpdate === true) {
        vendorStore = this.getVendor;
        vendorSpecification = this.getVendorSpecification;
        vendorSpaces = this.getVendorSpace;
      }

      let organizationId = null;
      if (this.form !== null) {
        organizationId = this.form.organization_id;
      }
      this.form = new Form(vendorStore);
      this.form.organization_id = organizationId;
      if (this.getIsVendorUpdate === true) {
        this.form.organization_id = vendorStore.organization_id;
      }
      this.form.vendor_specifications = new Form(vendorSpecification);
      this.form.vendor_spaces = []; //[new Form(vendorSpaces)];
      this.form.hotel_features = [];
      this.form.hotel_features = hotelFeatures;
      this.form.hotel_features = _.map(this.form.hotel_features, hotel_feature => {
        return new Form({
          name: hotel_feature.name,
          icon: hotel_feature.icon,
          selected: false,
          removable: false
        });
      });
      this.form.restaurants = [];
      this.form.bars = [];
      this.form.opening_hours = locationOpeningHours;
      this.form.equipments = equipments;
      this.form.catering = cateringTypes;
      this.form.event_spaces = [];
      this.form.drafts = [];
      this.form.gallery = {
        outside: { type: "outside", images: [] },
        meeting_rooms: { type: "meeting_rooms", images: [] },
        logo: { type: "logo", images: [] },
        rooms: [{ type: "rooms", images: [] }],
        spaces: [{ type: "spaces", images: [] }],
        restaurants: [{ type: "restaurants", images: [] }],
        bars: [{ type: "bars", images: [] }]
      };
      this.form.activities = [];
      this.form.rooms = [];
      this.form.seasonal_timings = [];
      this.form.hotel_types = hotelTypes;
      this.form.type = type;

      // Update Action
      if (this.getIsVendorUpdate === true) {
        // Vendor Spaces
        if (vendorStore.vendor_spaces && vendorStore.vendor_spaces.length > 0) {
          this.form.vendor_spaces = _.map(vendorStore.vendor_spaces, vendorSpace => {
            return new Form(vendorSpace);
          });
        }

        // Equipments
        this.form.equipments = [];
        if (vendorStore.equipments && vendorStore.equipments.length > 0) {
          this.form.equipments = _.map(vendorStore.equipments, equipment => {
            return new Form(equipment);
          });
        }

        // Catering
        this.form.catering = [];
        if (vendorStore.catering && vendorStore.catering.length > 0) {
          this.form.catering = _.map(vendorStore.catering, catering => {
            return new Form(catering);
          });
        }
        // Vendor Specification
        this.form.vendor_specifications.is_reception_always_available = vendorStore.is_reception_always_available;

        // Opening Hours
        this.form.opening_hours = [];
        if (vendorStore.opening_hours) {
          vendorStore.opening_hours.forEach(opening_hour => {
            if (opening_hour.day.replace(/ /g, "") !== "") {
              this.form.opening_hours.push({
                day: opening_hour.day,
                end_time: opening_hour.end_time,
                is_open: opening_hour.is_open,
                start_time: opening_hour.start_time
              });
            }
          });
        }

        // Seasonal Timings
        this.form.seasonal_timings = [];
        vendorStore.seasonal_timings.forEach(seasonal_timing => {
          if (seasonal_timing.start_date.replace(/ /g, "") !== "") {
            this.form.seasonal_timings.push({
              start_date: seasonal_timing.start_date,
              start_date_menu: seasonal_timing.start_date_menu,
              end_date: seasonal_timing.end_date,
              end_date_menu: seasonal_timing.end_date_menu,
              removable: seasonal_timing.removable
            });
          }
        });

        // Event Types
        this.form.event_types = vendorStore.event_types;

        // Gallery (START)
        let outside = [];
        let meeting_rooms = [];
        let logo = [];
        let rooms = [];
        let spaces = [];
        let restaurants = [];
        let bars = [];
        let restaurantMenu = [];
        let barMenu = [];
        if (vendorStore.gallery) {
          // Room Images
          _.forEach(vendorStore.rooms, room => {
            rooms.push({ type: "rooms", images: _.map(room.images, "url") });
          });

          // Space Images
          _.forEach(vendorStore.vendor_spaces, space => {
            spaces.push({ type: "spaces", images: _.map(space.images, "url") });
          });

          // Restaurants
          let restaurantImages = _.values(_.groupBy(_.filter(vendorStore.gallery, { property: "restaurant" }), "name"));
          _.forEach(vendorStore.restaurants, (restaurant, restaurantIndex) => {
            restaurants.push({
              type: "restaurants",
              images: _.map(restaurantImages[restaurantIndex], "url")
            });
          });

          // Bars
          let barImages = _.values(_.groupBy(_.filter(vendorStore.gallery, { property: "bar" }), "name"));
          _.forEach(vendorStore.bars, (bar, barIndex) => {
            bars.push({
              type: "bars",
              images: _.map(barImages[barIndex], "url")
            });
          });

          //Restaurant Menu Images
          let menuImages = vendorStore.gallery.length
            ? vendorStore.gallery.filter(image => image.property == "restaurant-menu")
            : [];
          menuImages.forEach(image => {
            restaurantMenu.push(image.url);
          });

          //Bar Menu Images
          menuImages = vendorStore.gallery.length
            ? vendorStore.gallery.filter(image => image.property == "bar-menu")
            : [];
          menuImages.forEach(image => {
            barMenu.push(image.url);
          });

          _.forEach(vendorStore.restaurant, (bar, barIndex) => {
            bars.push({
              type: "bars",
              images: _.map(barImages[barIndex], "url")
            });
          });

          outside = _.map(_.filter(vendorStore.gallery, { property: "outside" }), "url");
          meeting_rooms = _.map(_.filter(vendorStore.gallery, { property: "meeting_rooms" }), "url");
          logo = _.map(_.filter(vendorStore.gallery, { property: "logo" }), "url");
        }
        this.form.gallery = {
          outside: { type: "outside", images: outside },
          meeting_rooms: { type: "meeting_rooms", images: meeting_rooms },
          logo: { type: "logo", images: logo },
          rooms: rooms.length > 0 ? rooms : [{ type: "rooms", images: [] }],
          spaces: spaces.length > 0 ? spaces : [{ type: "spaces", images: [] }],
          restaurants: restaurants.length > 0 ? restaurants : [{ type: "restaurants", images: [] }],
          bars: bars.length > 0 ? bars : [{ type: "bars", images: [] }]
          //restaurant_menu :{type: 'restaurant-menu', images: restaurantMenu},
          //bar_menu :{type: 'bar-menu', images: barMenu},
        };
        // Gallery (END)

        if (vendorStore.type === "hotel") {
          // Hotel Types
          let hotels = [];
          vendorStore.hotel_types.forEach(ht => {
            hotels.push(ht);
          });
          this.form.hotel_types = [];
          hotels.forEach(hotel => {
            if (hotel.name.replace(/ /g, "") !== "") {
              this.form.hotel_types.push({
                name: hotel.name,
                icon: hotel.icon,
                selected: hotel.selected
              });
            }
          });

          // Rooms (START)
          if (vendorStore.rooms && vendorStore.rooms.length > 0) {
            this.form.rooms = [];
            _.forEach(vendorStore.rooms, (room, roomIndex) => {
              let item = {
                categories: ["Basic", "Superior", "Deluxe", "Other"],
                selected_category: room.category,
                calculated_rooms: room.total_rooms_in_category,
                removable: true,
                room_types: []
              };

              //this.form.rooms.push( item );
              _.forEach(room.room_details, (roomDetail, roomDetailIndex) => {
                let typeItem = {
                  features: roomDetail.features,
                  removable: roomDetail.removable,
                  selected_features: roomDetail.selected_features,
                  selected_type: roomDetail.selected_type,
                  total_room_types: roomDetail.total_room_types,
                  types: roomDetail.types,
                  bed_types: []
                };
                _.forEach(roomDetail.bed_types, bedType => {
                  typeItem.bed_types.push(new Form(bedType));
                });
                //this.form.rooms[roomIndex].room_types[roomDetailIndex].bed_types.push(roomDetail.bed_types);
                item.room_types.push(new Form(typeItem));
              });

              this.form.rooms.push(new Form(item));
            });
          }
          // Rooms (END)

          // Hotel Features
          let selected_features = _.map(vendorStore.hotel_features, feature => {
            return new Form(feature);
          });
          let defaultFeatures = Object.assign([], this.form.hotel_features);
          let newFeatures = vendorStore.hotel_features.filter(function(obj) {
            return defaultFeatures.indexOf(obj) === -1;
          });
          _.forEach(newFeatures, feature => {
            if (feature.name.replace(/ /g, "") !== "") {
              this.form.hotel_features.push({
                name: feature.name,
                icon: feature.icon,
                selected: feature.selected,
                removable: feature.removable
              });
            }
          });
          this.form.hotel_features = _.uniqBy(this.form.hotel_features, "name");
          if (selected_features) {
            selected_features.forEach(selected => {
              this.form.hotel_features.forEach((feature, index) => {
                if (feature.name === selected.name) {
                  this.form.hotel_features[index].selected = true;
                }
              });
            });
          }

          this.form.restaurants = _.map(vendorStore.restaurants, restaurant => {
            return new Form(restaurant);
          });
          this.form.bars = _.map(vendorStore.bars, bar => {
            return new Form(bar);
          });
          this.form.vendor_specifications.total_meeting_spaces = vendorSpecification.total_meeting_spaces;
          this.form.event_spaces = _.map(vendorStore.event_spaces, eventSpace => {
            return new Form(eventSpace);
          });

          vendorStore.activities.forEach(activity => {
            if (activity.name.replace(/ /g, "") !== "") {
              this.form.activities.push({
                name: activity.name,
                editable: activity.editable,
                removable: activity.removable
              });
            }
          });
        }
      }
    },
    addNewVendor() {
      this.setToggleSlider(true);
      if (this.isAdmin === true) {
      }
    },
    orgSelected(orgId) {
      this.filterOrganization = orgId;
    },
    resetForm() {
      this.resetNewVendor();
      this.resetVendorErrors();
      this.setActiveComponent("home");
      this.initForm("");
      this.setIsVendorUpdate(false);
      this.form.organization_id = null;
    },
    resetPagination() {
      this.setVendors([]);
      this.resetVendorPagination();
      this.loaderId += 1;
    },
    ...mapActions(["loadVendor", "loadLocation", "updateVendor", "updateSubscription", "loadVendors"]),
    ...mapMutations([
      // Vendor
      "setNewVendor",
      "resetNewVendor",
      "resetVendorErrors",
      "setVendorByIdAndType",
      "resetVendorPagination",
      "setVendors",

      // Location
      "setNewLocation",
      "resetNewLocation",
      "resetLocationErrors",

      "setToggleSlider",
      "setActiveVendorType",
      "setActiveComponent",
      "setIsVendorUpdate"
    ])
  },
  /*created() {
    setTimeout(() => {
      let vendors = this.vendors;
      if ( vendors ) { this.setActiveVendorType(vendors[0].type); }
    }, 2000);
  },*/
  watch: {
    filterOrganization(v) {
      this.resetPagination();
    },
    filterStatus(v) {
      this.resetPagination();
    },
    filterType(v) {
      this.resetPagination();
    },
    searchVendors(v) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.resetPagination();
      }, 500);
    },
    getToggleSlider(value) {
      let main = document.getElementsByClassName("details-content");
      if (value === true) {
        main[0].style.overflowY = "hidden";
        this.initForm(this.getActiveComponent);
      } else {
        main[0].style.overflowY = "scroll";
        this.resetForm();
      }
    },
    drawer(value) {
      this.$emit("drawer", value);
    }
  }
};
</script>

<style scoped>
.add-vendor-btn {
  position: absolute;
  right: 5px;
  color: black !important;
}
.style-search {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-top: 0 !important;
}
.preview-image {
  border-radius: 10px;
  height: 90px;
}
.margin {
  margin: auto;
}
.width {
  max-width: 250px;
}
.cursor-pointer {
  cursor: pointer;
}
.ml-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.line-normal {
  line-height: 1rem;
}
.item-width {
  width: 58px;
}
</style>
